














































import { Vue, Component } from "vue-property-decorator";
import DataQualityTable from "@/components/dataQuality/dataQualityTable/DataQualityTable.vue";
import DataQualityIndicatorLegend from "@/components/dataQuality/DataQualityIndicatorLegend.vue";
import DataOutliersTakeActionModal from "@/components/dataQuality/dataOutliersModals/DataOutliersTakeActionModal.vue";
import DataOutliersViewHistoryModal from "@/components/dataQuality/dataOutliersModals/DataOutliersViewHistoryModal.vue";
import { DataQualityHeader } from "@/types/dataQuality/dataQualityTable";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import DataQualityModule from "@/store/clients/DataQuality.module";
import VesselsModule from "@/store/clients/Vessels.module";

const Vessels = getModule(VesselsModule, store);
const DataQuality = getModule(DataQualityModule, store);

@Component({
  components: {
    DataQualityTable,
    DataQualityIndicatorLegend,
    DataOutliersTakeActionModal,
    DataOutliersViewHistoryModal,
  },
})
export default class DataOutliers extends Vue {
  takeActionDialog = false;
  viewHistoryDialog = false;

  async created(): Promise<void> {
    await Promise.all([Vessels.refreshExtendedVessels(), DataQuality.refreshDataOutliers()]);
  }

  headers: DataQualityHeader[] = [
    {
      text: "Vessel",
      type: "string",
      value: "vesselName",
      width: "200px",
    },
    {
      text: "Overall Status",
      type: "dataIndicator",
      value: "overallStatus",
      width: "150px",
      tooltip: "Indicates the overall status of outliers found of each vessel.",
    },
    {
      text: "Percentage (%) Total Data that are Outliers",
      type: "string",
      value: "header",
      children: [
        {
          text: "STW",
          type: "percentIndicator",
          value: "stw",
          width: "100px",
        },
        {
          text: "Draft",
          type: "percentIndicator",
          value: "draft",
          width: "100px",
        },
        {
          text: "SP#1",
          type: "percentIndicator",
          value: "speedLog",
          width: "100px",
        },
        {
          text: "SP#2",
          type: "percentIndicator",
          value: "gefoc1",
          width: "100px",
        },
        {
          text: "ME #1FOC",
          type: "percentIndicator",
          value: "speedLog",
          width: "100px",
        },
        {
          text: "ME #2FOC",
          type: "percentIndicator",
          value: "speedLog",
          width: "100px",
        },
        {
          text: "G/E #1FOC",
          type: "percentIndicator",
          value: "speedLog",
          width: "100px",
        },
        {
          text: "G/E #2FOC",
          type: "percentIndicator",
          value: "speedLog",
          width: "100px",
        },
        {
          text: "G/E #3FOC",
          type: "percentIndicator",
          value: "speedLog",
          width: "100px",
        },
        {
          text: "G/E #4FOC",
          type: "percentIndicator",
          value: "speedLog",
          width: "100px",
        },
      ],
    },
  ];

  get isEnvDevelopment(): boolean {
    return false;
  }

  get dataOutliers(): any {
    var dataOutliers = DataQuality.dataOutliers.map((item, index) => ({
      key: index,
      vesselName: {
        value: Vessels.extendedVessels.find(vessel => vessel.id === item.vesselId)?.name,
        link: null,
      },
      overallStatus: {
        value: item.overallStatus,
        link: null,
      },
      stw: {
        value: item.data.find(row => row.featureName === "STW")?.value,
        link: null,
      },
      draft: {
        value: item.data.find(row => row.featureName === "Draft")?.value,
        link: null,
      },
      speedLog: {
        value: item.data.find(row => row.featureName === "speedLog")?.value,
        link: null,
      },
      gefoc1: {
        value: item.data.find(row => row.featureName === "gefoc1")?.value,
        link: null,
      },
      gefoc2: {
        value: item.data.find(row => row.featureName === "gefoc2")?.value,
        link: null,
      },
    }));
    return dataOutliers;
  }
}
